import React from 'react';
import './App.css';
import Navbar from './navbar';
import profilePic1 from './img/portfolio_pic.jpg'; 
import profilePic2 from './img/portfolio_pic2.jpg'; 

function Home() {
  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <div className="left-section">
          <h1 className="main-heading">Kim's Portfolio</h1>
          <p className="sub-heading">Learn more about me by exploring my portfolio! :D</p>
        </div>
        <div className="right-section">
          <img src={profilePic1} alt="Profile Pic 1" className="profile-pic" />
          <img src={profilePic2} alt="Profile Pic 2" className="profile-pic" />
        </div>
      </div>
    </div>
  );
}

export default Home;

import React from 'react';
import Navbar from './navbar';
import sushi from './img/sushi.jpg'; 
import sushi1 from './img/sushi1.jpg'; 
import sushi2 from './img/sushi2.jpg'; 
import './App.css';

function Resume() {
  const downloadResume = process.env.PUBLIC_URL + '/Resume_Kimberley_Kam_Sing.pdf';
  return (
    <div className="App">
    <Navbar />
    <h1 className="content-heading">Technology</h1>
    <div className="content">
      <div className="tech-section">
      <div className="top-left-block">
  <h2>Proficient Programming Languages</h2>
  <ul>
    <li>Java</li>
    <li>HTML/CSS</li>
    <li>C#</li>
  </ul>
</div>
</div>
<div className="tech-section">
  <div className="top-left-block">
    <h2>Familiar Programming Languages</h2>
    <ul>
      <li>JavaScript (ReactJS)</li>
      <li>Kotlin</li>
      <li>TypeScript (Angular)</li>
      <li>Swift</li>
      <li>C++</li>
      <li>PHP</li>
      <li>Python</li>
    </ul>
  </div>
</div>
<div className="tech-section">
  <div className="top-left-block">
    <h2>Software Applications</h2>
    <ul>
      <li>IntelliJ</li>
      <li>Github</li>
      <li>Gitbash</li>
      <li>Docker</li>
      <li>Unity</li>
      <li>Visual Studio, Visual Studio Code</li>
      <li>Jira</li>
      <li>SSMS</li>
      <li>Figma</li>
    </ul>
  </div>
</div>

      <div className="education-section">
      <h1 className="content-heading">My Education</h1>

      <section className="design-section">
      <div className="timeline">
        <div className="timeline-empty"></div>
        <div class="timeline-middle">
    <div class="timeline-circle"></div>
</div>
<div class="timeline-component timeline-content">
    <h3>CEGEP CHAMPLAIN COLLEGE SAINT-LAMBERT 2021-2024</h3>
    <h4>Computer Science Technology | DEC</h4>
    <ul>
        <li>Winter semesters 2022 and 2023 Honor’s List</li>
        <li>2023-2024's Badminton team female MVP</li>
        <li>Badminton team’s social media manager and team representative in the school’s sports council</li>
    </ul>
</div>
<div class="timeline-component timeline-content">
    <h3>COLLÈGE JEAN DE LA MENNAIS 2018-2021</h3>
    <h4>High School Diploma</h4>
    <ul>
        <li>Recipient of the “Distinction in studies” in 2021</li>
    </ul>
</div>
<div class="timeline-middle">
    <div class="timeline-circle"></div>
</div>
<div class="timeline-empty"></div>
<div class="timeline-empty"></div>
<div class="timeline-middle">
    <div class="timeline-circle"></div>
</div>
<div class="timeline-component timeline-content">
    <h3>ÉCOLE SECONDAIRE MGR-A-M-PARENT 2016-2018</h3>
    <h4>High School | Science and Technology Concentration</h4>
    <ul>
        <li>Volunteered for Valentine’s Day and Christmas events</li>
        <li>Winner of the regional’s badminton tournament 2017-2018</li>
    </ul>
</div>
</div>
</section>
</div>

    
  
      <div className="work-experience">
  <h1 className="content-heading">Work Experience</h1>
  <div className="experience-item">
    <h2>SUSHIMAN, YUZU SUSHI - BROSSARD | AUGUST 2022 - AUGUST 2023</h2>
    <ul>
      <li>Prepare food, serve customers, deal with conflicts that arise, and train new employees.</li>
    </ul>
    <div className="experience-images">
      <img src={sushi} alt="Sushi Image 1" />
      <img src={sushi1} alt="Sushi Image 2" />
      <img src={sushi2} alt="Sushi Image 3" />
    </div>
  </div>
  <div className="experience-item">
    <h2>ASSEMBLY WORKER, STELPRO – SAINT-BRUNO | JUNE 2019 - AUGUST 2019</h2>
    <ul>
      <li>Worked in teams of 5 employees to assemble various parts of heating appliances.</li>
    </ul>
  </div>
</div>
      <div className="download-resume">
        <a href={downloadResume} download="Resume_Kimberley_Kam_Sing.pdf">
        <button class="button-30" role="button">Download My Resume</button>
        </a>
      </div>


    </div>
    </div>
  
  );
}

export default Resume;

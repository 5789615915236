import React from 'react';
import Navbar from './navbar';
import ecp1 from './img/ecp1.png'; 
import ecp2 from './img/ecp2.png'; 
import ecp3 from './img/ecp3.png'; 
import vet1 from './img/vet1.png'; 
import vet2 from './img/vet2.png'; 
import vet3 from './img/vet3.png'; 
import game1 from './img/game1.png'; 
import game2 from './img/game2.png'; 
import game3 from './img/game3.png'; 
import { useEffect, useState } from 'react';

function Projects() {
  const [schoolProjects, setSchoolProjects] = useState([]);
    useEffect(() => {
      fetch(`https://canadawidecarparts.nn.r.appspot.com/api/v1/projects/school`)
        .then((response) => response.json())
        .then((data) => setSchoolProjects(data))
        .catch((error) => console.error('Error fetching projects:', error));
    }, []);
  return (
    <div className="App">
      <Navbar />
      <div className="content">

      <div className="projects-section">
  <h1 className="content-heading">Professional Projects</h1>
  <div className="project-item">
    <h2>E-COMMERCE WEBSITE SELLING CAR PARTS FOR AN EXTERNAL CLIENT | E-commerce Website for a Startup Business called Canada Wide Car Parts | September 2023 – February 2024</h2>
    <ul>
      <li>Develop an e-commerce application to sell car parts for our external client dealing with transactions, invoices, client profiles and inventory.</li>
      <li>Collaborate within a team of 4 people to conceptualize, design, and implement a robust 3-tier application.</li>
      <li>Use technologies such as: Spring Boot, React, GitHub, GitBash, MySQL, IntelliJ, and 3rd parties for authentication and transaction, and use AGILE and SCRUM methodologies.</li>
    </ul>
    <div className="project-images">
      <img src={ecp1} alt="ecp project Image 1" />
      <img src={ecp2} alt="ecp project Image 2" />
      <img src={ecp3} alt="ecp project Image 3" />
    </div>
  </div>
  <div className="project-item">
    <h2>CHAMPLAIN PETCLINIC Simulation of a Real-World Application of a Pet Clinic Website | September 2023 – November 2023</h2>
    <ul>
      <li>Collaborated with the VETS microservice’s team members of 6 to help develop new and old functionalities of the application.</li>
      <li>Collaborated with different teams to optimize application performance.</li>
      <li>Acted as the role of SCRUM master and developer using Jira, Spring Boot, Angular, GitHub, GitBash, PostgreSQL, MongoDB, and IntelliJ.</li>
    </ul>
    <div className="project-images">
    <img src={vet1} alt="vet project Image 1" />
      <img src={vet2} alt="vet project Image 2" />
      <img src={vet3} alt="vet project Image 3" />
    </div>
  </div>
  <div className="project-item">
    <h2>UNITY PLATFORM GAME Platform gaming project – Berry Hunter: The Fox’s Journey | March 2023 – May 2023</h2>
    <ul>
      <li>Developed and designed game levels and overall visual appeal of a 2D platform game with a teammate using Unity Engine, Visual Studio, C#, GitHub, and GitBash.</li>
    </ul>
    <div className="project-images">
    <img src={game1} alt="game project Image 1" />
      <img src={game2} alt="game project Image 2" />
      <img src={game3} alt="game project Image 3" />
    </div>
  </div>
</div>

</div>
</div>
  );
}

export default Projects;

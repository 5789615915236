import React from 'react';
import Navbar from './navbar';
import emailIcon from './img/email.png';
import githubIcon from './img/github.png';
import linkedinIcon from './img/linkedin.png';
import './contact.css';

function Contact() {
  return (
    <div className="App">
      <Navbar />
      <div className="contact-content">
        <h1 className="main-heading">Where to Contact Me</h1>
        <div className="contact-section-box">
          <div className="contact-info-box">
            <div className="contact-item-box">
              <img src={emailIcon} alt="Email" className="contact-icon-box" />
              <button>
                <a href="mailto:jadekamsing@gmail.com">Jadekamsing@gmail.com</a>
              </button>
            </div>
            <div className="contact-item-box">
              <img src={githubIcon} alt="GitHub" className="contact-icon-box" />
              <button>
                <a href="https://github.com/ayasophya">github.com/ayasophya</a>
              </button>
            </div>
            <div className="contact-item-box">
              <img src={linkedinIcon} alt="LinkedIn" className="contact-icon-box" />
              <button>
                <a href="https://www.linkedin.com/in/kimberley-kam-sing-4aa892291/">linkedin.com/in/kimberley-kam-sing</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import art from './img/art.jpg';
import art1 from './img/art1.jpg';
import art2 from './img/art2.jpg';

function CarouselComponent2() {
  return (
    <Carousel interval={null} slide={true}>
      <Carousel.Item>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <img
            className="d-block"
            src={art}
            alt="team picture"
            style={{ width: '500px', height: '500px', marginBottom: '20px' }} // Adjust width and height as needed
          />
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <img
            className="d-block"
            src={art1}
            alt="Second slide"
            style={{ width: '500px', height: '700px', marginBottom: '20px' }} // Adjust width and height as needed
          />
        </div>
        <Carousel.Caption className="text-center">
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <img
            className="d-block"
            src={art2}
            alt="Second slide"
            style={{ width: '900px', height: '600px', marginBottom: '20px' }} // Adjust width and height as needed
          />
        </div>
        <Carousel.Caption className="text-center">
        </Carousel.Caption>
      </Carousel.Item>
      
      
    </Carousel>
  );
}

export default CarouselComponent2;

import React from 'react';
import Navbar from './navbar';
import Zelexio from './img/Zelexio.png'; // Import your internship image here

function Experience() {
  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <h1 className="content-heading">Experience</h1>
        <div className="internship-item">
          <div className="internship-details">
            <h2>Internship at Zelexio</h2>
            <p>March 2024 - May 2024</p>
            <ul>
            <li>Work in collaboration with other developers</li>
            <li>Frontend developer using Vue.js</li>
              <li>Participate in meetings</li>
              <li>Bring new ideas</li>
            </ul>
            <h2>Skills learned</h2>
            <ul>
              <li>Learning a new framework: Vue.js</li>
              <li>Using different tools: GitLab, Vue extension</li>
              <li>Independence and autonomy</li>
            </ul>
          </div>
          <div className="internship-image">
            <img src={Zelexio} alt="Internship" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;

import React from 'react';
import { Link } from 'react-router-dom';
import cat from "./img/logo.jpg"

function Navbar() {
   return (
    <nav className="navbar">
      <div className="navbar-container">
        <img src={cat} alt="Profile Pic 1" className="navbar-logo" style={{ marginLeft: '15px' }}></img>
        <ul className="navbar-menu">
        <li className="navbar-item"><Link to="/home">Home</Link></li>
        <li className="navbar-item"><Link to="/about">About</Link></li>
        <li className="navbar-item"><Link to="/resume">Resume</Link></li>
        <li className="navbar-item"><Link to="/projects">Projects</Link></li>
        <li className="navbar-item"><Link to="/experience">Experience</Link></li>
        <li className="navbar-item"><Link to="/contact">Contact Me</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;

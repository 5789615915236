import React from 'react';
import './App.css';
import Navbar from './navbar';
import About from './About';
import Resume from './Resume';
import Projects from './Projects';
import Contact from './Contact';
import Home from './home'; 
import Experience from './Experience';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/experience" element={<Experience />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import sport from './img/sport.jpg';
import sport1 from './img/sport1.jpg';
import sport2 from './img/sport2.jpg';
import sport3 from './img/poster.jpg';
import award from './img/award.jpg'

function CarouselComponent() {
  return (
    <Carousel interval={null} slide={true}>
      <Carousel.Item>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <img
            className="d-block"
            src={award}
            alt="team picture"
            style={{ width: '700px', height: '530px', marginBottom: '20px' }}
          />
        </div>
        <Carousel.Caption className="text-center">
          <h3>2023-2024's Team Female MVP and excellent academic achievement awards</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <img
            className="d-block"
            src={sport}
            alt="team picture"
            style={{ width: '700px', height: '360px', marginBottom: '20px' }}
          />
        </div>
      </Carousel.Item>
     
         <Carousel.Item>
        <div className="d-flex justify-content-center align-items-center flex-column">
          <img
            className="d-block"
            src={sport3}
            alt="Third slide"
            style={{ width: '400px', height: '600px', marginBottom: '20px' }} // Adjust width and height as needed
          />
        </div>
        <Carousel.Caption className="text-center">
          <h3>I appear on some school posters!</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselComponent;

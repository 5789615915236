import React from 'react';
import './App.css';
import Navbar from './navbar'; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CarouselComponent from './Carousel';
import CarouselComponent2 from './Carousel2';
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <div className="content-heading">
          <h1 className="main-heading">Hi! My name is Kimberley</h1>
          <p>
            I am a 20-year-old graduating student in Champlain College's Computer Science Technology program.
          </p>
        </div>
        <div className="hobbies">
          <h2 className="section-heading">My Hobbies and Interests</h2>
          <p className="hobby-description">
          </p>
          <div className="hobby-section">
            <div className="hobby">
              <h3>Badminton</h3>
              <p className="hobby-description">
              Something I have been doing for the longest time is badminton.
              <br/>
              Since badminton is something I'm very passionate about, I dedicated a big part of my life honing my skills.
              <br/>
              I have made so many valuable friends along the way, and I'm proud of my progress and achievements!
              </p>
              <CarouselComponent/>
            </div>
            <div className="hobby">
              <h3>Art</h3>
              <p className="hobby-description">
              Art is something I'm very passionate about. I enjoy expressing myself through various mediums, including digital art, pens and pencils, paints, ink, and clay crafting.
              <br/> I've been drawing since little, and it's a hobby that I've developed a deep fondness for over the years.
              <br/>
              Ideally, I would love to incorporate art into my career :)
              </p>
              <CarouselComponent2/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
